import type { GuaranteesDescription } from '@orus.eu/backend/src/routers/contracts'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import type { CustomerInformation } from '@orus.eu/backend/src/views/user-account-view'
import { useParams } from '@tanstack/react-router'
import type { ReactElement } from 'react'
import { trpc } from '../../../../../client'
import { assert } from '../../../../../lib/errors'
import { useApi } from '../../../../../lib/use-api/use-api'
import { GlobalLoadingState } from '../../../../molecules/global-loading-state'
import { ContractPageContent } from '../../common/backoffice-contract-page/contract-page-content'

export default function PlatformContractPage(): ReactElement {
  const { subscriptionId } = useParams({ from: '/bak/contracts/$subscriptionId/' })
  assert(subscriptionId, 'This page requires a subscriptionId param')

  const contractData = useApi(loadContractPageData, subscriptionId)

  if (!contractData.ready) {
    return <GlobalLoadingState />
  }

  const { contract, customer, guaranteesDescription } = contractData.data

  return (
    <ContractPageContent
      type="platform"
      contract={contract}
      customer={customer}
      guaranteesDescription={guaranteesDescription}
    />
  )
}

async function loadContractPageData(subscriptionId: string): Promise<{
  contract: BackofficeContractDescription
  customer: CustomerInformation
  guaranteesDescription: GuaranteesDescription
}> {
  const [contract, customer, guaranteesDescription] = await Promise.all([
    trpc.contracts.getContract.query(subscriptionId),
    trpc.contracts.getContractCustomer.query(subscriptionId),
    trpc.contracts.getContractGuarantees.query(subscriptionId),
  ])

  return {
    contract,
    customer,
    guaranteesDescription,
  }
}
